
import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

// const links = [
//   {
//     text: "Tutorial",
//     url: "https://www.gatsbyjs.com/docs/tutorial",
//     description:
//       "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
//   },
//   {
//     text: "Examples",
//     url: "https://github.com/gatsbyjs/gatsby/tree/master/examples",
//     description:
//       "A collection of websites ranging from very basic to complex/complete that illustrate how to accomplish specific tasks within your Gatsby sites.",
//   },
//   {
//     text: "Plugin Library",
//     url: "https://www.gatsbyjs.com/plugins",
//     description:
//       "Learn how to add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
//   },
//   {
//     text: "Build and Host",
//     url: "https://www.gatsbyjs.com/cloud",
//     description:
//       "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
//   },
// ]

// const samplePageLinks = [
//   {
//     text: "Page 2",
//     url: "page-2",
//     badge: false,
//     description:
//       "A simple example of linking to another page within a Gatsby site",
//   },
//   { text: "TypeScript", url: "using-typescript" },
//   { text: "Server Side Rendering", url: "using-ssr" },
//   { text: "Deferred Static Generation", url: "using-dsg" },
// ]

// const moreLinks = [
//   { text: "Join us on Discord", url: "https://gatsby.dev/discord" },
//   {
//     text: "Documentation",
//     url: "https://gatsbyjs.com/docs/",
//   },
//   {
//     text: "Starters",
//     url: "https://gatsbyjs.com/starters/",
//   },
//   {
//     text: "Showcase",
//     url: "https://gatsbyjs.com/showcase/",
//   },
//   {
//     text: "Contributing",
//     url: "https://www.gatsbyjs.com/contributing/",
//   },
//   { text: "Issues", url: "https://github.com/gatsbyjs/gatsby/issues" },
// ]

// const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`

const ExcerptsPage = () => (
  <Layout>
<Seo title="Excerpts | Leah Jordain" />
<hr className={styles.bluepurplehr}></hr>
<h2>Our Own Language (Picture Book)</h2>
<h3>Selected for the Latinx in Publishing mentorship.</h3>
<h3>A story about a young Latina who learns that love is stronger than the linguistic barrier between herself and her grandparents.</h3>
<p>"…Our language is the feel of your hands in ours<br></br>
when we walk through the front door to go to the park</p>

<p>It is the wind in your hair<br></br>
when Abuelito pushes you on the swing</p>

<p>And the harmony of our laughter<br></br>
when we watch cartoons on the sofa in the evening.</p>


<p>Our language is the music of the morning<br></br>
when we wake up to eat mango and chirimoya with the windows open wide</p>

<p>It is the tears you cry that afternoon<br></br>
when you fall from your bicycle and I clean the scrape on your knee</p>

<p>And the face we make<br></br>
when we try the burnt llapingachos your abuelito cooked for dinner.</p>


<p>Our language is the whisper of rain on our skin<br></br>
when you ask to run outside into the morning storm</p>

<p>It is the smile we share<br></br>
when we’re planting new seeds in the muddy garden</p>

<p>And the colors of the rainbow above us<br></br>
when we gaze up into the sky…"</p>

<hr className={styles.bluepurpleexcerptshr}></hr>


<h2>The Absolute Value of Me + You (YA Contemporary)</h2>
<h3>Selected for the We Need Diverse Books Mentorship Critique Program.</h3>
<h3>In this fake-dating story, sixteen-year-old coder Nina Sanchez is on a mission to get into her high school’s computer club and prove her skills, despite her math learning disability. But when her school discriminates against her, Nina’s only chance to get into the club is through an unlikely alliance with Jessica Stevens<span style={{letterSpacing: -5,}}>—</span>—the smart, popular, and terrifyingly-beautiful candidate running for student body president.</h3>
<p>"There were many ways to deal with the stress of waiting for the most important appointment of your life, and I liked to think that hacking into your school’s computer network was one of the more reasonable ones. I mean, lots of kids did drugs and stuff to cope with the stress in their lives. I just typed some stuff on my laptop’s keyboard. Sure, it wasn’t technically legal, and maybe it wasn’t the wisest thing to do right outside Bouchard High School’s administrative office. But really, it was the school’s own fault for not updating their security.</p>
<p>“I can<em>not</em> believe that worked,” my cousin Celia said, leaning in close to peer at my laptop’s screen. </p>
<p>Beside me, our best friend Evan did a cursory scan of the fluorescent-lit hallway. We were sitting on the bench between the administrative office and the computer lab, eleven minutes before my appointment started (not that I was counting)…"</p>

<hr className={styles.bluepurpleexcerptshr}></hr>

<h2>Much Ado About Everything (YA Contemporary)</h2>
<h3>Selected for the Author Mentor Match mentorship.</h3>
<h3>A Latinx retelling of <em>Much Ado About Nothing</em>. Beatriz and her (annoyingly attractive) ex-boyfriend Ben are competing for a life-changing Mock Trial scholarship. Then Beatriz's cousin's quinceañera turns into a school-wide scandal, and everyone starts saying Latinas are nothing more than their sexualized stereotypes. Now Beatriz will have to get the scholarship to prove them wrong<span style={{letterSpacing: -5,}}>—</span>—while trying not to fall back in love with the boy who could ruin it all.</h3>

<p>"As far as I could tell, there were exactly three reasons that someone would join Messina High School’s Mock Trial club. Either you’d been forced to enroll by your parents (most common), you were nerdy enough to want to be there (least common), or you were seeking an outlet to display your over-inflated ego (my ex-boyfriend). In my entire year of being a member, not a single person outside the first two categories had come through our classroom’s repainted-brown-but-chipping-back-down-to-green door. And staring at that same door now, on the first day of my sophomore year in Mock Trial, I was pretty sure those categories hadn’t changed.</p>


<p>It was the third one that was new. Any minute now, the other half of my disastrous secret summer romance would come through that door, here to join/ruin my favorite club for the first time.</p>


<p>I wished he'd just show up already and get it over with…"</p>

<hr className={styles.bluepurpleexcerptshr}></hr>

<h2>La Madrastra y The Servant Girl (Short Story)</h2>
<h3>In this retelling of <em>Cinderella</em> set in the time of the Incas, Prince Amaru must protect his loved ones from a monster terrorizing the kingdom<span style={{letterSpacing: -5,}}>—</span>—and at the same time, learn what monsters really are.</h3>
<p>"Some said it was Maria Angula who stole the children at night.</p>
<p>She clawed her way through from the world of the dead, or so wizened viejas liked to tell sleepy youths just before bedtime. Some days, if you’re very quiet, you can hear her slithering on her belly outside, stalking our little pueblo. No, don’t look. She might notice you standing at the window. You see, one day when Maria was young, she’d used the guts of a man lying dead in his grave to make her hungry husband some soup. That night, the dead man dragged Maria from her bed and into her own grave as revenge, her nails digging deep grooves into the ground as she fought. And if you go outside after bedtime, you might find one of those muddy hands wrapped around your own ankle, and she might drag <em>you</em> into a grave next…"</p>

  </Layout>
)

export default ExcerptsPage

